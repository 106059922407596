import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import MenuIcon from '../MenuIcon';
import theme from '../../utils/theme';
import book from '../../images/Book/book.png';
import home from '../../images/Book/home.png';
import pdf from '../../images/popup/tsc.jpeg';

// import Popup from '../../components/Popup';

class Header extends React.Component {
  state = {
    drawerOn: false,
  }

  toggleDrawer = () => {
    this.setState({ drawerOn: !this.state.drawerOn });
  }

  render() {
    const {drawerOn} = this.state;
    return (
      <React.Fragment>
      <StyledULbutton1>
            <a href="https://www.hotelhilltone.com" rel="noreferrer">
              
              <img  alt="brand-logo"  src={home} height={50}/>
            </a>
      </StyledULbutton1>
      
      <StyledULbutton>
          
            <a href="https://bookings.hotelhilltone.com/?propertyId=8826" target="_blank" rel="noreferrer">
              <Button class="shadow-none">BOOK NOW!</Button>

            </a>
      </StyledULbutton>
      <StyledULbutton2>
          
            <a href={pdf} target="_blank" rel="noreferrer">
              <Buttonn class="shadow-none">T<small>&</small>C</Buttonn>

            </a>
      </StyledULbutton2>
      <StyledULbutton3>
          
            <a href="https://www.hotelhilltone.com/#/happening" target="_blank" rel="noreferrer">
              <Buttonnh class="shadow-none">HAPPENINGS</Buttonnh>

            </a>
      </StyledULbutton3>

          


          
        <StyledHeader>
          <StyledLink to="/about">About us</StyledLink>
          <StyledLink to="/rooms">Accommodation</StyledLink>
          <StyledLink to="/dining">Dining</StyledLink>
          <StyledLink to="/wellness">Wellness</StyledLink>
          <StyledLink to="/meetings-and-events">Meetings &amp; Events</StyledLink>
          <StyledLink to="/experiences">Experiences</StyledLink>
          <StyledLink to="/special-offers">Testimonials</StyledLink>
          <StyledLink to="/contact">Hotel Policy</StyledLink>
        </StyledHeader>
        <HeaderMobile>
          {/* <StyledMobileBrandIcon>
            <StyledAnchor href="/">
              <img width={100} height={50} alt="brand-logo" src={require('../../images/demo.png')} />
            </StyledAnchor>
          </StyledMobileBrandIcon> */}
          <StyledIconOff style={{ display: drawerOn ? 'none' : 'flex' }}>
            <MenuIcon showCross={false} toggleDrawer={this.toggleDrawer} {...this.props} />
          </StyledIconOff>
        </HeaderMobile>
        <StyledDrawer drawerOn={drawerOn}
          style={{ opacity: drawerOn ? '1' : '0' }}
        >
          <StyledHeaderMobile>
            <StyledIconOn>
              <MenuIcon showCross={true} toggleDrawer={this.toggleDrawer} {...this.props} />
            </StyledIconOn>
            <StyledULMobile>
              <StyledAnchorMobile to={"/about"}>About us</StyledAnchorMobile>
              <StyledAnchorMobile to={"/rooms"}>Accommodation</StyledAnchorMobile>
              <StyledAnchorMobile to={"/dining"}>Dining</StyledAnchorMobile>
              <StyledAnchorMobile to={"/wellness"}>Wellness</StyledAnchorMobile>
              <StyledAnchorMobile to={"/meetings-and-events"}>Meetings &amp; Events</StyledAnchorMobile>
              <StyledAnchorMobile to={"/experiences"}>Experiences</StyledAnchorMobile>
              <StyledAnchorMobile to={"/special-offers"}>Testimonials</StyledAnchorMobile>
              <StyledAnchorMobile to={"/contact"}>Hotel Policy</StyledAnchorMobile>
            </StyledULMobile>
          </StyledHeaderMobile>
        </StyledDrawer>
      </React.Fragment>
    );
  }
}

export default Header;

const StyledHeader = styled.div`
  width: 100%;
  height: 10vh;
  background: ${theme.primary};
  position: absolute;
  top: 90vh;
  z-index: 100;

  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-right: 2px solid #FDFDF9;
  
  color: white;
  font-size: 1.3rem;
  cursor: pointer;

  &:hover {
    color: rgba(255, 255, 255, 0.75);
    transition: 0.3s ease;
  }
  color: #fff;
  text-decoration: none;

  &:hover {
    color: ${theme.primary};
    background: white;
    transition: 0.7s ease;
  }
`;

const HeaderMobile = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 110;
  display: flex;
  justify-content: space-between;
  height: 10vh;
  align-items: center;
  @media (min-width: 900px) {
    display: none;
  }
`;

const StyledIconOff = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 4rem;
  max-height: 150px;
`;

const StyledDrawer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  width: 100vw;
  height: 100vh;
  background: ${theme.primary};
  z-index: 999;
  transition: 1s ease;
  opacity: 0;
  pointer-events: none;
  ${({drawerOn}) => drawerOn && `
    opacity: 1;
    pointer-events: auto;
  `}
`;

const StyledHeaderMobile = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledIconOn = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  min-height: 10vh;
  padding: 0 1rem 0 0;
`;

const StyledULMobile = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -10vh;
`;

const StyledULbutton1 = styled.div`
  position: fixed;
  top: 40%;
  right: 10px;
  z-index:999;
`;
const StyledULbutton = styled.div`
  position: fixed;
  top: 48%;
  right: 10px;
  z-index:999;
`;
const StyledULbutton3 = styled.div`
  position: fixed;
  top: 55%;
  right: 10px;
  z-index:999;
`;
const StyledULbutton2 = styled.div`
  position: fixed;
  top: 61%;
  right: 10px;
  z-index:999;
`;


const StyledAnchorMobile = styled(Link)`
  font-family: 'Open Sans', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4rem;
  max-height: 150px;
  text-decoration: none;
  padding-left: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1001;
  color: #fff;
  &:hover {
    color: #fff;
    font-weight: bold;
    transition: font-weight 0.2s;
  }
  @media (max-size: 767px) {
    font-size: 2rem;
  }
`;


const Styledbut = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  background:#45461f;
  padding:10px;
  border-radius: 100px;
  
  font-size: 1rem;
  cursor: pointer;
  margin-bottom:5px;
  font-family: sans-serif;

  &:hover {
    color: rgba(255, 255, 255, 0.75);
    transition: 0.3s ease;
  }
  color: #fff;
  text-decoration: none;

  &:hover {
    color: ${theme.primary};
    background: white;
    transition: 0.7s ease;
  }
`;

const Button = styled.button`
  border-radius: 6px;
  background: #fbb03f;
  padding: 6px 16px;
  color: #45461f;
  font-family: "Times New Roman", Times, serif;
  font-size:20px;
   font-style: italic;
  border: none;
  font-weight: bold;

   -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;

  @-webkit-keyframes glowing {
  0% { background-color: #fbb03f; -webkit-box-shadow: 0 0 3px #fbb03f; }
  50% { background-color: #FEC166; -webkit-box-shadow: 0 0 40px #FEC166; }
  100% { background-color: #fbb03f; -webkit-box-shadow: 0 0 3px #fbb03f; }
}

@-moz-keyframes glowing {
   0% { background-color: #fbb03f; -webkit-box-shadow: 0 0 3px #fbb03f; }
  50% { background-color: #FEC166; -webkit-box-shadow: 0 0 40px #FEC166; }
  100% { background-color: #fbb03f; -webkit-box-shadow: 0 0 3px #fbb03f; }
}

@-o-keyframes glowing {
    0% { background-color: #fbb03f; -webkit-box-shadow: 0 0 3px #fbb03f; }
  50% { background-color: #FEC166; -webkit-box-shadow: 0 0 40px #FEC166; }
  100% { background-color: #fbb03f; -webkit-box-shadow: 0 0 3px #fbb03f; }
}

@keyframes glowing {
  0% { background-color: #fbb03f; box-shadow: 0 0 3px #fbb03f; }
  50% { background-color: #FEC166; box-shadow: 0 0 40px #FEC166; }
  100% { background-color: #fbb03f; box-shadow: 0 0 3px #fbb03f; }
}
`;

const Buttonn = styled.button`
  border-radius: 50%;
  background: #45461f;
  padding: 10px;
  color: white;
  font-family: "Times New Roman", Times, serif;
  border: none;
  font-size:19px;
`;

const Buttonnh = styled.button`
  border-radius: 25px;
  background: #45461f;
  padding: 6px 11px;
  color: white;
  font-family: "Times New Roman", Times, serif;
  border: none;
  font-size:19px;
`;
const Styledbuta = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  
  
  font-size: 1rem;
  cursor: pointer;
  margin-bottom:5px;
  
`;