import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { Modal } from 'semantic-ui-react';

import Header from '../../../components/Header';
import img from '../../../images/landing_img1.jpg';
import theme from '../../../utils/theme';
import Engine from '../../../components/Engine';
import LargeTextWithLine from '../../../components/LargeTextWithLine';
import Footer from '../../../components/Footer';
import commentImage from '../../../images/home/R.Suite.png';
import SmallSliderHome from '../../../components/SmallSliderHome/SmallSliderHome';
import Comment from './comments';

import logoImg from '../../../images/hilltone1.png';
import img1 from '../../../images/FeaturedRooms/Greenhouse_Premier.png';
import img2 from '../../../images/FeaturedRooms/Executive.png';
import img3 from '../../../images/FeaturedRooms/Rock_suite.png';
import img4 from '../../../images/FeaturedRooms/Villas.png';
// import diwali from '../../../images/diwali.jpeg';
import Popup from '../../../components/Popup';
import Popup2 from '../../../components/Popup2';

class Content extends React.Component {
  state = {
    modalOpen: true,
  }

  scrollDown = (ref) => {
    this.ref1.scrollIntoView({ behavior: 'smooth' });
  }

  getGreeting = () => {
    let x = new Date();
    const hours = parseInt(x.toString().split(':')[0].slice(-2), 10);
    if (hours < 12) {
      return 'Good Morning';
    } else if (hours < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  }

  render() {
    const featuredRoomsText = ['Greenhouse Premier Rooms', 'Suites', 'Rock Suite', 'Luxury Rooms'];
    const featuredImageLinks = [img1, img2, img3, img4];
    const urls = ['premier', 'executive', 'rock', 'villa'];
    return (

      <StyledContent>
        
        <Logo to="/" style={{ paddingTop: '100px' }}>
        </Logo>
        <CenterContent>
          <Filter style={{ 'padding': '0.5rem 1rem' }} >{this.getGreeting()}, <span style={{ fontStyle: 'normal', fontFamily: 'OFL-Sorts' }}>Welcome to Hotel Hilltone </span></Filter>
        </CenterContent>
        <Header white={true} />
        <div ref={(el) => { this.ref1 = el; }}></div>
        
        
        {/* <div>
          <a
            href="https://secure.staah.com/common-cgi/package/packagebooking.pl?propertyId=13623&checkIn=2019-10-08&checkOut=2019-10-09&noofrooms=1&adult0=2&child0=0&ccode=&unk=1094"
          >Click</a>
        </div> */}
        <Engine style={{ textAlign: 'center !important' }} />
        {/* <BookNow /> */}
        <FeaturedRooms>
          <LargeTextWithLine style={{ marginTop: '2rem' }} width={"150px"} text={"Featured Rooms"} />
          <SimpleGallery>
            {featuredRoomsText.map((text, i) => (
              <ImageContainer
                imgLink={featuredImageLinks[i]}
                key={"feturedText" + i + 1}
                onClick={e => this.props.history.push(`/${urls[i]}`)}
              >
                <TextFilter>{text}</TextFilter>
              </ImageContainer>
            ))}
          </SimpleGallery>
          
        </FeaturedRooms>
        <Gallery>
          <LargeTextWithLine width={"125px"} marginTop={'1rem'} text={"Photo Gallery"} />
          <SmallSliderHome />
        </Gallery>
        
        <Footer />
        {/* <Modal style={{maxWidth: '490px'}} onClose={e => this.setState({modalOpen: false})} open={this.state.modalOpen} closeIcon>
          <Modal.Content>
            <StyledDiwali style={{width: '100%', float: 'right'}}><span style={{opacity: 0}}>&#10006;</span></StyledDiwali>
            <StyleImgModal src={diwali} alt="diwali offers modal" />
          </Modal.Content>
        </Modal> */}
      </StyledContent >
    );
  }
}

export default Content;

// const StyledDiwali = styled.div`
//   @media only screen and (min-width: 900px) {
//     display: none;
//   }
// `;

// const StyleImgModal = styled.img`
//   max-width: 100%;
// `;

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1200px;
  & > div > div > div > ul > li {
    background: #0000 !important;
  }
`;

const Heading = styled.div`
  font-family: OFL Sorts Mill Goudy TT;
  font-style: italic;
  font-weight: 500;
  font-size: 48px;
  line-height: 73px;
  text-align: center;
  color: #FFFFFF;
`;
// const Centercontainer = styled.div`
//  text-align: center;
// `;
const StyledContent = styled.div`
  width: 100%;
  height: 100vh;

  background-image: url(${img});
  background-repeat: no-repeat;
  background-width: 100%;
  background-size: cover;
  background-position:center;
  background-attachment: local;
  font-size: 1.4rem;
`;

const CenterContent = styled.div`
  height: 80%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 17vh;
  @media only screen and (max-width: 800px) {
    margin-top: 20vh;    
  }
`;

const Filter = styled.button`
  font-family: 'OFL-Sorts';
  font-style: italic;
  color: ${theme.primary};
  background: #fff;
  padding: 1.5rem 3rem;
  margin-top: 1.2rem;
  font-size: 1.4rem;
  border: none;
  outline: none;
  cursor: pointer;
  max-width: 90%;
  box-shadow: 0 16px 28px rgba(173, 173, 173, 0.5), 0 7px 10px rgba(0, 0, 0, 0.22);

  &:hover {
    background: ${theme.primary};
    color: #fff;
    opacity: 1;
    box-shadow: 0 16px 28px rgba(10, 10, 10, 0.5), 0 7px 10px rgba(0, 0, 0, 0.22);
    transition: 1.2s ease;
  }
`;

const Logo = styled(Link)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 10%;
`;

const FeaturedRooms = styled.div`
      width: 100%;
      margin-bottom: 3rem;
      padding: 2.5rem 0;
    `;

const SimpleGallery = styled.div`
  width: 100%;
  height: 400px;
  display: grid;
  grid-template-columns: repeat(4, auto);
  @media only screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-top: 50px;
  }
`;

const ImageContainer = styled.div`
  height: 100%;
  background-image: url(${props => props && props.imgLink});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: local;
  position: relative;
  cursor: pointer;
  will-change: opacity, transform;
      
  &:hover {
    & > div {
    opacity: 0.9;
      transform: translate(0, -350%);
      transition-duration: 0.7s;
    }
    opacity: 0.6;
    transition-duration: 0.7s;
  }

  @media only screen and (max-width: 800px) {
    height: 250px;
    &:hover {
      & > div {
        transform: translate(0);
      }
    }
  }
`;

const TextFilter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  font-size: 1.2rem;
  color: #463c00;
  background: #ddd;
  opacity: 0.7;
  text-align: center;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Gallery = styled.div`
  padding: 2.5rem 0;
  @media only screen and (max-width: 800px) {
    padding: 1rem 0;
    & > div {
      margin-bottom: 2rem;
    }
  }
`;

const GuestSpeech = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  opacity: .8;
  color: #fff;
  background-image: linear-gradient( rgba(0,0,0,.6),rgba(0,0,0,.6)), url(${commentImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-width: 100%;
  background-position: center;
  background-attachment: local;
`;